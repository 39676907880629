import { CommonModule } from '@angular/common'
import { Component, HostBinding, inject, OnInit, signal } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms'
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask'
import { filter, switchMap } from 'rxjs'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { BodyClassObserverService, CepService, removeAccents } from '@monorepo-channels/shared/util-helpers'
import {
	BillingAddress,
	ClientActions,
	EncryptCardDataDto,
	getErrorRegisterCard,
	getRegisterCardStatus,
} from '@monorepo-channels/channels/domain'
import { Store } from '@ngrx/store'
import { FooterButtonsComponent, LoadingPageComponent } from '@monorepo-channels/components/ui-pm'
import { MatButtonModule } from '@angular/material/button'
import { Actions, ofType } from '@ngrx/effects'
import { Router, RouterLink } from '@angular/router'
import { MatBottomSheet } from '@angular/material/bottom-sheet'
import { CardFailureComponent } from '../simple-bottom-sheets/card-failure.component'

@Component({
	standalone: true,
	imports: [
		CommonModule,
		MatIconModule,
		MatFormFieldModule,
		MatInputModule,
		ReactiveFormsModule,
		NgxMaskDirective,
		NgxMaskPipe,
		FooterButtonsComponent,
		MatButtonModule,
		RouterLink,
		LoadingPageComponent,
	],
	selector: 'feature-pm-new-card',
	templateUrl: './new-card.component.html',
	styleUrls: ['./new-card.component.scss'],
})
export class NewCardComponent implements OnInit {
	states = [
		{ name: 'Acre', id: 'AC' },
		{ name: 'Alagoas', id: 'AL' },
		{ name: 'Amapá', id: 'AP' },
		{ name: 'Amazonas', id: 'AM' },
		{ name: 'Bahia', id: 'BA' },
		{ name: 'Ceará', id: 'CE' },
		{ name: 'Distrito Federal ', id: 'DF' },
		{ name: 'Espírito Santo ', id: 'ES' },
		{ name: 'Goiás ', id: 'GO' },
		{ name: 'Maranhão ', id: 'MA' },
		{ name: 'Mato Grosso ', id: 'MT' },
		{ name: 'Mato Grosso do Sul ', id: 'MS' },
		{ name: 'Minas Gerais ', id: 'MG' },
		{ name: 'Pará ', id: 'PA' },
		{ name: 'Paraíba ', id: 'PB' },
		{ name: 'Paraná ', id: 'PR' },
		{ name: 'Pernambuco ', id: 'PE' },
		{ name: 'Piauí ', id: 'PI' },
		{ name: 'Rio de Janeiro ', id: 'RJ' },
		{ name: 'Rio Grande do Norte ', id: 'RN' },
		{ name: 'Rio Grande do Sul ', id: 'RS' },
		{ name: 'Rondônia ', id: 'RO' },
		{ name: 'Roraima ', id: 'RR' },
		{ name: 'Santa Catarina ', id: 'SC' },
		{ name: 'São Paulo ', id: 'SP' },
		{ name: 'Sergipe ', id: 'SE' },
		{ name: 'Tocantins ', id: 'TO' },
	]

	selectedState = 'Estado'
	private store = inject(Store)
	private actions$ = inject(Actions)
	private router = inject(Router)
	status$ = this.store.select(getRegisterCardStatus)
	private fb = inject(FormBuilder)
	private cepService = inject(CepService)
	public principal$ = inject(BodyClassObserverService).observeClass('principal')
	creditCardForm = this.fb.nonNullable.group({
		number: ['', [Validators.required, Validators.minLength(16), Validators.maxLength(16)]],
		name: ['', Validators.required],
		validity: ['', Validators.required],
		cvv: ['', Validators.required],
		billingAddress: this.fb.nonNullable.group({
			zipCode: ['', Validators.required],
			state: ['', Validators.required],
			city: ['', Validators.required],
			address: ['', Validators.required],
		}),
	})
	showCvv = false
	public neighborhood = signal<string | undefined>(undefined)
	private bottomSheet = inject(MatBottomSheet)

	constructor() {
		this.creditCardForm.controls.billingAddress.controls.zipCode?.valueChanges
			.pipe(
				filter(zipCode => zipCode.toString().length >= 8),
				switchMap(zipCode => this.cepService.findCep(zipCode)),
				takeUntilDestroyed()
			)
			.subscribe(({ state, city, address, neighborhood, error }) => {
				if (error) return
				this.selectedState = this.states.find(item => item.id === state)?.name ?? 'Estado'
				this.neighborhood.set(neighborhood)
				this.creditCardForm.controls.billingAddress.patchValue({
					state,
					city,
					address,
				})
			})

		this.actions$
			.pipe(
				ofType(ClientActions.registerCardFailure),
				switchMap(() => this.store.select(getErrorRegisterCard)),
				takeUntilDestroyed()
			)
			.subscribe(error => {
				this.bottomSheet.open(CardFailureComponent, { data: { error } })
			})
	}

	ngOnInit(): void {
		this.creditCardForm.reset()
		this.store.dispatch(ClientActions.resetCardStatus())
		const category = history.state['category']
		if (category) {
			this.creditCardForm.controls.number.setValue(category.bin)
		}
	}

	@HostBinding('style')
	get style() {
		return {
			'--mat-form-field-container-text-size': '14px',
			'--mdc-text-field--outline': '360px',
			'mat-mdc-text-field-wrapper': '360px',
		}
	}

	handleSubmit() {
		if (this.creditCardForm.invalid) return
		const billingAddress = this.creditCardForm.controls.billingAddress.value as BillingAddress
		this.store.dispatch(
			ClientActions.addNewCard({
				encryptData: this.encryptData,
				billingAddress: { ...billingAddress, country: 'BR' },
			})
		)
	}

	private get encryptData(): EncryptCardDataDto {
		const [expMonth, expYear] = this.creditCardForm.controls.validity.value!.split('/')
		const encryptCard = {
			number: String(this.creditCardForm.controls.number.value) as string,
			holder: removeAccents(this.creditCardForm.controls.name.value as string),
			cvv: this.creditCardForm.controls.cvv!.value as string,
			expMonth: +expMonth,
			expYear: +`20${expYear}`,
		}
		return encryptCard
	}

	toggleCvvVisibility() {
		this.showCvv = !this.showCvv
	}
}
