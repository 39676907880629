<main class="container" [ngClass]="{principal: principal$ | async}">
	<h1 class="title">Como funciona</h1>

	@for(step of steps; track step.id) {
	<div class="step" (click)="selectedStep.set(step.id)" [ngClass]="{active: selectedStep() === step.id}">
		<div class="title-container">
			<div class="circle">{{step.id}}</div>
			<h3 class="title-step">{{step.title}}</h3>
		</div>
		<div class="content" *ngIf="selectedStep() === step.id">
			<p>{{step.content}}</p>
		</div>
	</div>

	}
	<!-- <div style="height: 120px"></div> -->
	<ui-pm-footer-buttons type="fixed-order">
		<button mat-raised-button routerLink="/">Ver restaurantes</button>
	</ui-pm-footer-buttons>
</main>
