<ul class="benefits" *ngIf="type === 'simple'">
	<li class="benefits__item" *ngFor="let benefit of sortedBenefits">
		<mat-icon svgIcon="pm-icons:check" style="width: 11px; height: 8px; margin-right: 8px"></mat-icon>
		@if (benefit.type === 'discount') {
			<span class="benefits__text" *ngIf="benefit.type === 'discount'">{{ benefit.value }} de </span>
			<span class="benefits__text">{{ benefit.name | lowercase }}</span>
		} @else {
			<span class="benefits__text">{{ benefit.name }}</span>
		}
	</li>
	<ng-container *ngIf="celebrations">
		<ui-common-or-divisor style="margin: 6px 0" *ngIf="benefits.length > 0" />
		<div style="display: flex; gap: 8px; font-size: 14px; font-weight: 360">
			<mat-icon svgIcon="pm-icons:celebrations" style="width: 24px; height: 24px"></mat-icon>
			<p>Celebrations</p>
		</div>
	</ng-container>
</ul>

<ng-container *ngIf="type === 'expansion'">
	<ul class="benefits">
		<li class="benefits__item" *ngFor="let benefit of sortedBenefits; let index = index">
			<mat-icon svgIcon="pm-icons:check" style="width: 11px; height: 8px; margin-right: 8px"></mat-icon>
			@if (benefit.type === 'discount') {
				<span class="benefits__text" *ngIf="benefit.type === 'discount'" (click)="openStep(index)"
					>{{ benefit.value }} de
				</span>
				<span class="benefits__text" (click)="openStep(index)">{{ benefit.name | lowercase }}</span>
			} @else {
				<span class="benefits__text" (click)="openStep(index)">{{ benefit.name }}</span>
			}
			<div style="margin-left: 20px">
				<p
					class="benefits__click"
					(click)="openStep(index)"
					*ngIf="!steps()[index].isExpanded && !steps()[index].isAnimating"
				>
					Ver detalhes
				</p>
				<div
					[class.expanded]="steps()[index].isExpanded"
					class="expandable-content"
					(click)="closeStep(index)"
				>
					{{ benefit.description }} <br /><br />
					{{ benefit.descriptionLong }}

					<p class="benefits__click" style="margin-top: 12px">Ver menos</p>
				</div>
			</div>
		</li>
	</ul>
	<!-- <ng-container *ngIf="celebrations">
		<div style="height: 100px"></div>
		<ui-common-or-divisor style="margin-bottom: 100px" />
		<div style="display: flex; gap: 8px; color: var(--content-txt); font-size: 14px; font-weight: 360">
			<mat-icon svgIcon="pm-icons:celebrations" style="width: 24px; height: 24px"></mat-icon>
			<p>Celebrations</p>
		</div>
	</ng-container> -->
</ng-container>
