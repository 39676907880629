import { CommonModule } from '@angular/common'
import { Component, inject } from '@angular/core'
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet'
import { MatButtonModule } from '@angular/material/button'
import { getStatusCancelOrder, OrderHistory, OrderHistoryActions } from '@monorepo-channels/channels/domain'
import { DivisorUiComponent } from '@monorepo-channels/components/ui-common'
import {
	BottomSheetHeaderComponent,
	FooterButtonsComponent,
	LoadingPageComponent,
} from '@monorepo-channels/components/ui-pm'
import { Actions, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { map, take } from 'rxjs'

@Component({
	standalone: true,
	imports: [
		CommonModule,
		BottomSheetHeaderComponent,
		FooterButtonsComponent,
		MatButtonModule,
		LoadingPageComponent,
		DivisorUiComponent,
	],
	selector: 'feature-pm-register-or-login',
	template: `
		<ui-pm-bottom-sheet-header [title]="'Cancelar ' + title" />

		<p style="margin: 20px; font-size: 14px">Tem certeza que deseja cancelar {{ intent }}?</p>
		<article class="order-card">
			<!-- <div class="order-card__image">
				<img [src]="order.venue.banners[0].value" />
			</div> -->
			<div class="order-card__details">
				<h2 class="order-card__name">{{ order.venue.name }}</h2>
				<p class="order-card__location">
					{{ order.venue.address.city }} - {{ order.venue.address.state }}
				</p>

				<div style="max-width: 146px">
					<ui-common-divisor top="12px" bottom="12px" />
				</div>
				<p class="order-card__time">
					<span style="font-weight: 480; width: 70px; display: inline-block"> Data:</span
					>{{ order.reservationDateTime | date: 'dd/MM/YYYY' }} <br />
					<span style="font-weight: 480; width: 70px; display: inline-block">Horário: </span
					>{{ order.date.time }}
				</p>
			</div>
		</article>

		@if (loading$ | async) {
			<div
				style="display: flex; justify-content: center; align-items: center; height: 96px; border-top: 1px solid var(--content-border); margin-top: 19px;"
			>
				<ui-pm-loading-page></ui-pm-loading-page>
			</div>
		} @else {
			<ui-pm-footer-buttons>
				<button mat-raised-button (click)="bottomSheetRef.dismiss()">Fechar</button>
				<button mat-raised-button color="primary" (click)="cancelOrder()">Cancelar</button>
			</ui-pm-footer-buttons>
		}
	`,
	styles: `
		.order-card {
			margin: 0 20px;
			padding: 16px 20px;
			background-color: #fff;
			box-shadow: var(--card-box-shadow);
			border-radius: 8px;


			&__image {
				max-width: 109px;
				min-width: 109px;
				max-height: 109px;
				overflow: hidden;
				border-radius: 8px;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			&__details {
				flex-grow: 1;
			}

			&__name {
				font-size: 20px;
				font-weight: bold;
				overflow: hidden;
				white-space: nowrap;
				@media (max-width: 375px) {
					width: 200px;
					text-overflow: ellipsis;
				}
			}

			&__time,
			&__location {
				font-size: 12px;
				color: var(--content-txt-black);
				font-weight: 360;
			}

			&__location {
				margin-bottom: 5px;
			}



			
		}
	`,
})
export class CancelOrderComponent {
	private store = inject(Store)
	private actions$ = inject(Actions)
	public loading$ = this.store.select(getStatusCancelOrder).pipe(map(status => status === 'pending'))
	public bottomSheetRef = inject(MatBottomSheetRef)
	public order: OrderHistory = inject(MAT_BOTTOM_SHEET_DATA)
	public intent = this.order.originalIntent === 'reservation' ? 'a reserva' : 'o check-in'
	public title = this.order.originalIntent === 'reservation' ? 'reserva' : 'check-in'

	cancelOrder() {
		this.bottomSheetRef.disableClose = true
		this.store.dispatch(OrderHistoryActions.cancelOrder({ orderId: this.order.id }))
		this.actions$.pipe(ofType(OrderHistoryActions.cancelOrderSuccess), take(1)).subscribe(() => {
			this.bottomSheetRef.dismiss()
		})
	}
}
