import { Component, ElementRef, Input, ViewChild } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { DivisorUiComponent } from '@monorepo-channels/components/ui-common'

@Component({
	standalone: true,
	selector: 'ui-pm-bottom-sheet-header',
	imports: [MatIconModule, DivisorUiComponent],
	template: `
		<div style="width: 100%; text-align: center; height: 12px">
			<mat-icon svgIcon="pm-icons:bar" style="width: 48px; height: 4px"></mat-icon>
		</div>
		<p
			style=" font-size: 16px; font-weight: 600; color: var(--content-txt-black); height: 52px; display: grid; place-items: center; "
		>
			{{ title }}
		</p>
		<ui-common-divisor bottom="20px" />
	`,
})
export class BottomSheetHeaderComponent {
	@Input({ required: true }) title!: string
	@Input() height = '50vh'
	@ViewChild('content') content!: ElementRef
}
