import { CommonModule } from '@angular/common'
import { Component, inject, signal } from '@angular/core'
import { MatBottomSheet } from '@angular/material/bottom-sheet'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { Router } from '@angular/router'
import { Category, getClient } from '@monorepo-channels/channels/domain'
import { Store } from '@ngrx/store'
import { RemoveCardComponent } from '../simple-bottom-sheets/remove-card.component'
import { BodyClassObserverService } from '@monorepo-channels/shared/util-helpers'

@Component({
	standalone: true,
	imports: [CommonModule, MatIconModule, MatButtonModule],
	selector: 'feature-pm-wallet',
	templateUrl: './wallet.component.html',
	styleUrl: './wallet.component.scss',
})
export class WalletComponent {
	private router = inject(Router)
	private store = inject(Store)
	public client$ = this.store.select(getClient)
	public selectedCard = signal(-1)
	private bottomSheet = inject(MatBottomSheet)
	public principal$ = inject(BodyClassObserverService).observeClass('principal')

	selectCard(index: number) {
		if (index === this.selectedCard()) {
			this.selectedCard.set(-1)
			return
		}
		this.selectedCard.set(index)
	}

	openRegisterCard(category?: Category) {
		this.router.navigate(['carteira/cadastrar-cartao'], { state: { category } })
	}

	removeCard(card: Category) {
		this.selectedCard.set(-1)
		this.bottomSheet.open(RemoveCardComponent, { data: card })
	}
}
