<main class="container" *ngIf="vm$ | async as vm" [ngClass]="{ principal: principal$ | async }">
	<h1 class="title">Reservas e check-ins</h1>

	<ui-pm-loading-page *ngIf="vm.loading"></ui-pm-loading-page>

	<section class="tab-container" *ngIf="!vm.loading">
		<div class="tab-buttons">
			<button [class.active]="selectedTab() === 'Ativos'" (click)="selectTab('Ativos')">Ativos</button>
			<button [class.active]="selectedTab() === 'Inativos'" (click)="selectTab('Inativos')">
				Inativos
			</button>
		</div>
		<div class="tab-content" *ngIf="selectedTab() === 'Ativos'" [@slideIn]="selectedTab() === 'Ativos'">
			@for (order of vm.actriveOrders; track order?.id) {
				<!-- Reservas ativas são aquelas que podem ser canceladas.-->
				<ng-container [ngTemplateOutlet]="orderCard" [ngTemplateOutletContext]="{ $implicit: order }">
				</ng-container>
			} @empty {
				<ng-container
					[ngTemplateOutlet]="notFoundOrder"
					[ngTemplateOutletContext]="{ $implicit: 'ativo' }"
				>
				</ng-container>
			}
		</div>

		<div
			class="tab-content"
			*ngIf="selectedTab() === 'Inativos'"
			[@slideIn]="selectedTab() === 'Inativos'"
		>
			@for (order of vm.inactiveOrders; track order?.id) {
				<!-- Reservas inativas são aquelas que não podem ser canceladas.-->
				<ng-container [ngTemplateOutlet]="orderCard" [ngTemplateOutletContext]="{ $implicit: order }">
				</ng-container>
			} @empty {
				<ng-container
					[ngTemplateOutlet]="notFoundOrder"
					[ngTemplateOutletContext]="{ $implicit: 'realizado' }"
				>
				</ng-container>
			}
		</div>
	</section>
</main>
<ng-template #orderCard let-order>
	<article class="order-card">
		<!-- <div class="order-card__image">
			<img [src]="order.venue.banners[0].value" />
		</div> -->
		<div class="order-card__details">
			<p class="order-card__time">
				{{ order.intent }} • {{ order.date.time }} •
				{{ order.reservationDateTime | date: 'dd/MM/YYYY' }}
			</p>
			<h2 class="order-card__name">{{ order.venue.name }}</h2>
			<p class="order-card__location">
				{{ order.venue.address.city }} - {{ order.venue.address.state }}
			</p>
			<div style="height: 10px"></div>
			<button class="order-card__cancel" (click)="cancelOrder(order)" *ngIf="order.canCancel$ | async">
				Cancelar reserva
			</button>
			<p class="order-card__cancel" style="cursor: initial" *ngIf="order.isCanceled">
				Reserva cancelada
			</p>
			<button class="order-card__view-details active" (click)="selectOrder(order.id)">
				<div class="order-card__view-details-target"></div>
				<mat-icon svgIcon="pm-icons:right-arrow" style="width: 15px; height: 15px"></mat-icon>
			</button>
		</div>
	</article>
</ng-template>

<ng-template #notFoundOrder let-type>
	<section style="display: flex; flex-direction: column; justify-content: center; align-items: center">
		<mat-icon svgIcon="pm-icons:calendar-history" style="width: 36px; height: 34px"> </mat-icon>
		<p
			style="
				font-size: 16px;
				font-weight: 600;
				margin-top: 16px;
				margin-bottom: 12px;
				text-align: center;
				color: var(--content-txt);
			"
		>
			Nenhuma reserva ou check-in {{ type }}
		</p>
		<p class="order-card__not-found">
			@if (type === 'ativo') {
				Você não tem nenhuma reserva ou check-in ativo no momento, explore os restaurantes e escolha
				sua próxima experiência
			} @else {
				Você ainda não realizou nenhuma reserva ou check-in, explore os restaurantes e escolha sua
				próxima experiência
			}
		</p>
		<button
			mat-raised-button
			routerLink="/"
			style="background: var(--background-page); --content-border-black: white"
		>
			Ver restaurantes
		</button>
	</section>
</ng-template>
