import { CommonModule } from '@angular/common'
import { Component, inject, OnInit, signal } from '@angular/core'
import { MatIcon } from '@angular/material/icon'
import {
	getSelectedVenue,
	getStatusAvailabilities,
	getStatusConfirmReservation,
	getErrorConfirmation,
	getSelectedSection,
	getSelectedDate,
	isLoadingConfirmReservation,
	getAvailabilities,
	getAvailabilitiesDates,
	getSections,
	getSchedules,
	getMinMaxPartySize,
	getSelectedPartySize,
	getReservationTimes,
	getSelectedReservationTime,
	getConfirmReservation,
	getClient,
	ReservationActions,
	VenueActions,
	ClientActions,
	Client,
	getVoucherValueReservation,
} from '@monorepo-channels/channels/domain'
import { Store } from '@ngrx/store'
import { combineLatest, map, Observable, take, tap } from 'rxjs'
import { MatTabsModule } from '@angular/material/tabs'
import { trigger, transition, style, animate } from '@angular/animations'
import {
	BenefitsUiComponent,
	CalendarUiComponent,
	CelebrationsRibbonComponent,
	FooterButtonsComponent,
	LoadingPageComponent,
	OrderSuccessComponent,
} from '@monorepo-channels/components/ui-pm'
import { ActivatedRoute, Router } from '@angular/router'
import { MatButtonModule } from '@angular/material/button'
import { MatRadioModule } from '@angular/material/radio'
import { FormsModule } from '@angular/forms'
import { TermsAndConditionsCheckboxComponent } from '../terms-and-conditions/checkbox/terms-and-conditions-checkbox.componen'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { DivisorUiComponent } from '@monorepo-channels/components/ui-common'
import { BodyClassObserverService, CapitalizeFirstPipe } from '@monorepo-channels/shared/util-helpers'
import { Actions, ofType } from '@ngrx/effects'
import { MatBottomSheet } from '@angular/material/bottom-sheet'
import { ReservationErrorComponent } from '../simple-bottom-sheets/reservation-error.component'
import { ChooseCelebrationsComponent } from '../choose-celebrations/choose-celebrations.component'
type ExtractObservableType<T> = T extends Observable<infer U> ? U : never

@Component({
	standalone: true,
	imports: [
		CommonModule,
		MatIcon,
		MatButtonModule,
		MatTabsModule,
		CalendarUiComponent,
		LoadingPageComponent,
		MatRadioModule,
		FormsModule,
		TermsAndConditionsCheckboxComponent,
		FooterButtonsComponent,
		DivisorUiComponent,
		CapitalizeFirstPipe,
		OrderSuccessComponent,
		ChooseCelebrationsComponent,
		BenefitsUiComponent,
		CelebrationsRibbonComponent,
	],
	selector: 'feature-pm-reservation',
	templateUrl: './reservation.component.html',
	styleUrl: './reservation.component.scss',
	animations: [
		trigger('slideIn', [
			transition(':enter', [
				style({ transform: 'translateX(100%)', opacity: 0 }),
				animate('300ms ease-out', style({ transform: 'translateX(0)', opacity: 1 })),
			]),
			transition(':leave', [
				animate('300ms ease-in', style({ transform: 'translateX(-100%)', opacity: 0 })),
			]),
		]),
	],
})
export class ReservationComponent implements OnInit {
	private route = inject(ActivatedRoute)
	private router = inject(Router)
	private store = inject(Store)
	private actions$ = inject(Actions)
	private bottomSheet = inject(MatBottomSheet)
	public showCelebrationOptions = signal(false)
	public selectedTab = signal('Data')
	public partysize = signal(1)
	public eula = signal(false)
	public eulaFromClient = signal(false)
	public editMode = signal(false)
	private venueId: string | null = null
	public selectedCelebration = signal<boolean>(false)
	public principal$ = inject(BodyClassObserverService).observeClass('principal')

	public vm$ = combineLatest([
		this.store.select(getSelectedVenue),
		this.store.select(getStatusAvailabilities),
		this.store.select(getStatusConfirmReservation),
		this.store.select(getErrorConfirmation),
		this.store.select(getSelectedSection),
		this.store.select(getSelectedDate),
		this.store.select(isLoadingConfirmReservation),
		this.store.select(getAvailabilities),
		this.store.select(getAvailabilitiesDates),
		this.store.select(getSections),
		this.store.select(getSchedules),
		this.store.select(getMinMaxPartySize),
		this.store.select(getSelectedPartySize),
		this.store.select(getReservationTimes),
		this.store.select(getSelectedReservationTime),
		this.store.select(getConfirmReservation),
		this.store.select(getClient),
		this.store.select(getVoucherValueReservation),
	]).pipe(
		map(
			([
				venue,
				statusAvailabilities,
				statusConfirmReservation,
				errorReservation,
				selectedSection,
				selectedDate,
				isLoadingConfirmReservation,
				availabilities,
				availabilitiesDates,
				sections,
				schedules,
				maxMinPartysize,
				selectedPartySize,
				reservationTimes,
				selectedReservationTime,
				confirmReservation,
				client,
				voucherValue,
			]) => ({
				venue,
				statusAvailabilities,
				statusConfirmReservation,
				errorReservation,
				selectedSection,
				selectedDate,
				isLoadingConfirmReservation,
				availabilities,
				availabilitiesDates,
				sections,
				schedules,
				maxMinPartysize,
				selectedPartySize,
				reservationTimes,
				selectedReservationTime,
				confirmReservation,
				client,
				voucherValue,
			})
		)
	)

	constructor() {
		this.store
			.select(getClient)
			.pipe(takeUntilDestroyed(), take(1))
			.subscribe(client => {
				this.eulaFromClient.set(client?.agreements.eula ?? false)
				this.eula.set(client?.agreements.eula ?? false)
				if (client?.voucher?.value) {
					this.showCelebrationOptions.set(true)
				}
			})

		this.actions$
			.pipe(ofType(ReservationActions.confirmReservationFail), takeUntilDestroyed())
			.subscribe({
				next: error => {
					this.bottomSheet.open(ReservationErrorComponent, { data: { error: error.error } })
				},
			})
	}

	ngOnInit(): void {
		this.store.dispatch(ReservationActions.resetReservationState())

		this.venueId = this.route.snapshot.paramMap.get('venueId')
		if (this.venueId) {
			this.store.dispatch(VenueActions.getOneVenue({ venueId: this.venueId }))
			if (!this.showCelebrationOptions()) {
				this.store.dispatch(ReservationActions.loadAvailabilities({ venueId: this.venueId }))
			}
		}
		this.store
			.select(getMinMaxPartySize)
			.pipe(
				take(1),
				tap(minMax => this.partysize.set(minMax?.min ?? 1))
			)
			.subscribe()
	}

	setCelebration(value: string | null, client: Client) {
		if (value === 'voucher' && this.venueId) {
			this.selectedCelebration.set(true)
			this.store.dispatch(
				ReservationActions.loadAvailabilities({
					venueId: this.venueId,
					voucherId: client.voucher?.id,
				})
			)
		} else if (value === 'benefits' && this.venueId) {
			this.store.dispatch(ReservationActions.loadAvailabilities({ venueId: this.venueId }))
			this.selectedCelebration.set(false)
		}
		this.showCelebrationOptions.set(false)
	}

	setReservationDay(day: string) {
		this.store.dispatch(ReservationActions.resetInitialStateSuccess())
		this.store.dispatch(ReservationActions.setSelectedDate({ selectedDate: day }))
	}

	setSection(sectionLabel: string, event: Event) {
		event.preventDefault()
		this.store.dispatch(ReservationActions.setSelectedSection({ selectedSectionLabel: sectionLabel }))
	}

	setPartysize(partysize: number) {
		this.store.dispatch(
			ReservationActions.setScheduleFromPartysize({ selectedPartySize: String(partysize) })
		)
	}

	setReservationTime(reservationTime: string, event: Event) {
		event.preventDefault()
		this.store.dispatch(
			ReservationActions.setReservationTime({ selectedReservationTime: reservationTime })
		)
	}

	public toggleTermsAndConditions(value: boolean) {
		this.eula.set(value)
	}

	selectTab(tab: string, vm: ExtractObservableType<typeof this.vm$>) {
		if (tab === 'Ambiente' && !vm.selectedDate) {
			return
		}
		if (tab === 'Mesa' && !vm.selectedSection && !vm.selectedDate) {
			return
		}
		// se clciar em Horário e não tiver selecionado partyzied OU secitno OU date
		if (tab === 'Horário' && (!vm.selectedPartySize || !vm.selectedSection || !vm.selectedDate)) {
			return
		}
		this.selectedTab.set(tab)
	}

	editReservation() {
		this.editMode.set(false)
	}

	reserve() {
		this.editMode.set(true)
	}

	confirmReservation() {
		this.store.dispatch(ReservationActions.confirmReservation())
		// só vou atualizar o cliente se o eula from client for false e o eula for true
		if (!this.eulaFromClient() && this.eula()) {
			this.store.dispatch(
				ClientActions.updateClientBackend({ client: { agreements: { eula: this.eula() } } })
			)
		}
	}

	isReservationDisabled(vm: ExtractObservableType<typeof this.vm$>) {
		return (
			!vm.selectedSection ||
			!vm.selectedPartySize ||
			!vm.selectedDate ||
			!vm.selectedReservationTime ||
			!this.eula()
		)
	}

	isDisabledTab(vm: ExtractObservableType<typeof this.vm$>) {
		if (this.selectedTab() === 'Data') {
			return !vm.selectedDate
		}
		if (this.selectedTab() === 'Ambiente') {
			return !vm.selectedSection
		}
		if (this.selectedTab() === 'Mesa') {
			return !vm.selectedSection || !vm.selectedPartySize || !vm.selectedDate
		}
		if (this.selectedTab() === 'Horário') {
			return !vm.selectedSection || !vm.selectedPartySize || !vm.selectedReservationTime
		}
		return true
	}

	nextTab() {
		if (this.selectedTab() === 'Data') {
			this.selectedTab.set('Ambiente')
			return
		}
		if (this.selectedTab() === 'Ambiente') {
			this.selectedTab.set('Mesa')
			return
		}
		if (this.selectedTab() === 'Mesa') {
			this.selectedTab.set('Horário')
			return
		}
	}

	okClick() {
		this.store.dispatch(ReservationActions.resetReservationState())
		this.router.navigate(['/'])
	}

	increase(maxPartysize: number | undefined = 10) {
		if (this.partysize() < maxPartysize) {
			this.partysize.update(value => value + 1)
			this.setPartysize(this.partysize())
		}
	}

	decrease(minPartySize: number | undefined = 1) {
		if (this.partysize() > minPartySize) {
			this.partysize.update(value => value - 1)
			this.setPartysize(this.partysize())
		}
	}
}
