import { CUSTOM_ELEMENTS_SCHEMA, Component, OnInit, inject, signal } from '@angular/core'
import { CommonModule, DOCUMENT } from '@angular/common'
import {
	AccordionModule,
	DivisorUiComponent,
	OrDivisorComponent,
} from '@monorepo-channels/components/ui-common'

import { ActivatedRoute, Router } from '@angular/router'
import {
	Client,
	ClientActions,
	getClient,
	getSelectedVenue,
	isLoadingSelectedVenue,
	VenueActions,
	VenueDetail,
} from '@monorepo-channels/channels/domain'
import { Store } from '@ngrx/store'
import {
	BenefitsUiComponent,
	FooterButtonsComponent,
	SwiperUiComponent,
	TagUiComponent,
} from '@monorepo-channels/components/ui-pm'
import { LoadingPageComponent } from '@monorepo-channels/components/ui-pm'
import { MatIconModule } from '@angular/material/icon'
import { MatRipple } from '@angular/material/core'
import { combineLatest, map, take, timer } from 'rxjs'
import { MatButtonModule } from '@angular/material/button'
import { MatBottomSheet } from '@angular/material/bottom-sheet'
import {
	CheckinDisabledComponent,
	CheckinUnavailableComponent,
	RegisterCardComponent,
	RegisterOrLoginComponent,
	ReservationDisabledComponent,
	SelectCardOptionsComponent,
} from '@monorepo-channels/feature-pm'
import { MatExpansionModule } from '@angular/material/expansion'
import { trigger, transition, style, animate } from '@angular/animations'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { BodyClassObserverService } from '@monorepo-channels/shared/util-helpers'

@Component({
	standalone: true,
	imports: [
		CommonModule,
		TagUiComponent,
		DivisorUiComponent,
		LoadingPageComponent,
		SwiperUiComponent,
		MatIconModule,
		MatRipple,
		MatButtonModule,
		BenefitsUiComponent,
		MatExpansionModule,
		FooterButtonsComponent,
		OrDivisorComponent,
		AccordionModule,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA], // why? because of the custom element <swiper-element>
	templateUrl: './venue-details.page.html',
	styleUrls: ['./venue-details.page.scss'],
	animations: [
		trigger('fadeInOut', [
			transition(':enter', [style({ opacity: 0 }), animate('200ms', style({ opacity: 1 }))]),
			transition(':leave', [animate('200ms', style({ opacity: 0 }))]),
		]),
	],
})
export class VenueDetailsPageComponent implements OnInit {
	private store = inject(Store)
	private route = inject(ActivatedRoute)
	private router = inject(Router)
	public loadingVenue$ = this.store.select(isLoadingSelectedVenue)
	private venueDetail$ = this.store.select(getSelectedVenue)
	private client$ = this.store.select(getClient)
	private document = inject(DOCUMENT)
	public copied = signal(false)

	public vm$ = combineLatest([this.venueDetail$, this.client$]).pipe(
		map(([venueDetail, client]) => ({ venueDetail, client }))
	)

	public principal$ = inject(BodyClassObserverService).observeClass('principal')

	public bottomSheet = inject(MatBottomSheet)
	private venueId: string | null = null

	ngOnInit(): void {
		this.venueId = this.route.snapshot.paramMap.get('venueId')
		this.venueId && this.store.dispatch(VenueActions.loadOneVenue({ venueId: this.venueId }))
	}

	constructor() {
		combineLatest([this.venueDetail$, this.client$])
			.pipe(takeUntilDestroyed())
			.subscribe(([venueDetail, client]) => {
				// Verifica se o cliente não está logado ou não possui vouchers
				const isUnauthorized = !client || !client.voucher

				// Se o venue for "isOnlyCelebration" e o cliente não for autorizado, redireciona para a home
				if (venueDetail?.isOnlyCelebration && isUnauthorized) {
					this.router.navigate(['/'])
				}
			})
	}

	toggleFavorite(venueId: string, client: Client | null) {
		if (!client) {
			this.bottomSheet.open(RegisterOrLoginComponent)
			return
		}
		this.store.dispatch(ClientActions.toggleFavorite({ venueId }))
	}

	share(venueDetail: VenueDetail) {
		const url = `${this.document.location.href}?shared=true`

		if (navigator.share) {
			navigator
				.share({
					title: venueDetail.name,
					text: 'Confira esse restaurante no Bradesco Programa Menu',
					url: url,
				})
				.catch(error => console.log('Sharing failed', error))
		} else {
			navigator.clipboard.writeText(url).then(() => {
				this.showCopiedNotification()
			})
		}
	}
	showCopiedNotification() {
		this.copied.set(true)
		timer(2000)
			.pipe(take(1))
			.subscribe(() => {
				this.copied.set(false)
			})
	}

	onCheckinClick(venueDetail: VenueDetail, client: Client | null) {
		if (!client) {
			this.bottomSheet.open(RegisterOrLoginComponent)
			return
		}
		if (venueDetail.checkin.status === 'disabled') {
			this.bottomSheet.open(CheckinDisabledComponent, { data: venueDetail._id })
			return
		}
		if (venueDetail.checkin.status === 'unavailable') {
			this.bottomSheet.open(CheckinUnavailableComponent, {
				data: { checkin: venueDetail.checkin, venueId: venueDetail._id },
			})
			return
		}
		if (!client.selectedCategory?.isRealCard) {
			this.bottomSheet.open(RegisterCardComponent)
			return
		}
		this.router.navigate(['checkin'], { relativeTo: this.route })
	}

	onReservationClick(venueDetail: VenueDetail, client: Client | null) {
		if (!client) {
			this.bottomSheet.open(RegisterOrLoginComponent)
			return
		}
		if (!venueDetail.reservation?.enabled) {
			this.bottomSheet.open(ReservationDisabledComponent)
			return
		}
		if (!client.selectedCategory?.isRealCard) {
			this.bottomSheet.open(RegisterCardComponent)
			return
		}
		this.router.navigate(['reserva'], { relativeTo: this.route })
	}

	goBack() {
		this.router.navigate(['/'])
	}

	openSelectCardOptions() {
		this.bottomSheet.open(SelectCardOptionsComponent, { data: { venueId: this.venueId } })
	}
}
