import { CommonModule } from '@angular/common'
import { Component, Input } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'

@Component({
	standalone: true,
	selector: 'ui-pm-celebrations-ribbon',
	imports: [MatIconModule, CommonModule],
	template: `
		<section style="background-color: var(--celebrations-background); padding: 20px">
			<div style="display: flex; align-items: center; gap: 16px" class="tablet-up-center">
				<mat-icon svgIcon="pm-icons:celebrations" style="--icon-color-red: #e1173f"></mat-icon>
				<div style="display: flex; flex-direction: column; gap: 4px">
					<p style="font-size: 18px; font-weight: 480">Celebrations</p>
					<p style="font-size: 14px; font-weight: 360">
						Voucher de <span style="font-weight: 480">{{ voucherValue | currency: 'BRL' }}</span>
					</p>
				</div>
			</div>
		</section>
	`,
})
export class CelebrationsRibbonComponent {
	@Input() voucherValue: string | null | undefined = null
}
