@if (vm$ | async; as vm) {
	@if (showCelebrationOptions()) {
		<feature-pm-choose-celebrations
			*ngIf="vm.client && vm.client.voucher && vm.venue && vm.venue.benefits"
			[voucherValue]="vm.client.voucher.value"
			[benefits]="vm.venue.benefits"
			(outputCelebration)="setCelebration($event, vm.client)"
			order="reserva"
		/>
	} @else {
		<ng-container *ngIf="editMode() && vm.statusConfirmReservation !== 'success'">
			<section class="container">
				<!-- <div [ngClass]="{ principal: principal$ | async }"> -->
				<p
					style="padding: 24px 20px; color: var(--content-txt)"
					*ngIf="vm.statusConfirmReservation !== 'pending'"
				>
					Revise os detalhes da sua reserva.
				</p>
			</section>
			<ui-common-divisor />

			<ng-container *ngIf="vm.statusConfirmReservation !== 'pending'">
				<article
					style="padding: 0 20px"
					class="container"
					[ngClass]="{ principal: principal$ | async }"
				>
					<h1 class="title" style="margin-top: 32px">Reserva</h1>
					<h2 class="venue-title">{{ vm.venue?.name }}</h2>
					<div style="display: flex; flex-direction: column; gap: 24px; margin-top: 24px">
						<p>
							<span class="edit-item">Nome</span>
							<span class="edit-value">{{ vm.client?.fullName }}</span>
						</p>
						<p>
							<span class="edit-item">Mesa para</span>
							<span class="edit-value"
								>{{ vm.selectedPartySize }}
								{{ vm.selectedPartySize === '1' ? 'pessoa' : 'pessoas' }}</span
							>
						</p>
						<p>
							<span class="edit-item">Ambiente</span>
							<span class="edit-value">{{ vm.confirmReservation.sectionLabel }}</span>
						</p>
						<p style="display: flex">
							<span class="edit-item">Data</span>
							<span class="edit-value">{{
								vm.confirmReservation.reservationDateUTC
									| date: "EEEE, d 'de' MMMM"
									| capitalizeFirst
							}}</span>
						</p>
						<p>
							<span class="edit-item">Horário</span>
							<span class="edit-value">{{ vm.confirmReservation.reservationTime }}</span>
						</p>
						<p style="display: flex">
							<span style="display: block; min-width: 120px" class="edit-item"
								>Localização</span
							>
							<span class="edit-value">{{ vm.venue?.address?.value }}</span>
						</p>
					</div>
				</article>
				<ui-pm-celebrations-ribbon
					[voucherValue]="vm.client.voucher.value"
					*ngIf="vm.client && vm.client.voucher && selectedCelebration()"
					style="margin-top: 38px; display: block"
				/>

				<section
					*ngIf="!selectedCelebration() && vm.venue && vm.venue.benefits"
					style="margin-top: 38px; padding: 0 20px"
					class="container"
					[ngClass]="{ principal: principal$ | async }"
				>
					<p class="benefits-title" style="margin-bottom: 20px; color: var(--content-txt)">
						Benefícios do seu cartão
					</p>
					<ui-pm-benefits [benefits]="vm.venue.benefits" type="expansion" />
				</section>
				<div style="height: 150px"></div>
			</ng-container>
			<ui-pm-loading-page
				*ngIf="vm.statusConfirmReservation === 'pending'"
				[ngClass]="{ principal: principal$ | async }"
			></ui-pm-loading-page>
			<!-- </div> -->
			<ui-pm-footer-buttons type="fixed-order">
				<button
					mat-raised-button
					(click)="editReservation()"
					[disabled]="vm.statusConfirmReservation === 'pending'"
				>
					Editar
				</button>
				<button
					mat-raised-button
					[color]="vm.statusConfirmReservation === 'pending' ? '' : 'primary'"
					[disabled]="vm.statusConfirmReservation === 'pending'"
					(click)="confirmReservation()"
				>
					Confirmar
				</button>
			</ui-pm-footer-buttons>
		</ng-container>

		<main
			class="container"
			*ngIf="!editMode() && vm.statusConfirmReservation !== 'success'"
			[ngClass]="{ principal: principal$ | async }"
		>
			<h1 class="title">Reserva</h1>
			<h2 class="venue-title">{{ vm.venue?.name }}</h2>

			<ul
				class="card-box-shadow"
				style="margin-top: 20px; margin-bottom: 32px; color: var(--content-txt)"
			>
				<li class="card-box-shadow__item">
					<mat-icon svgIcon="pm-icons:profile" style="width: 24px; height: 24px"></mat-icon>
					{{ vm.client?.fullName }}
				</li>
			</ul>

			<section class="tab-container" [ngClass]="selectedTab()">
				<div class="tab-buttons">
					<button [class.active]="selectedTab() === 'Data'" (click)="selectTab('Data', vm)">
						Data
					</button>
					<button [class.active]="selectedTab() === 'Ambiente'" (click)="selectTab('Ambiente', vm)">
						Ambiente
					</button>
					<button [class.active]="selectedTab() === 'Mesa'" (click)="selectTab('Mesa', vm)">
						Mesa
					</button>
					<button [class.active]="selectedTab() === 'Horário'" (click)="selectTab('Horário', vm)">
						Horário
					</button>
				</div>
				<div
					class="tab-content"
					*ngIf="selectedTab() === 'Data'"
					style="display: flex; justify-content: center"
				>
					@if (vm.statusAvailabilities === 'pending') {
						<ui-pm-loading-page>Carregando a disponibilidade</ui-pm-loading-page>
					} @else {
						<ui-pm-calendar
							*ngIf="vm.availabilitiesDates as availabilities"
							[availabilitiesDates]="availabilities"
							(daySelected)="setReservationDay($event.dateString)"
							[selectedDate]="vm.selectedDate"
						></ui-pm-calendar>
					}
				</div>
				<div class="tab-content" *ngIf="selectedTab() === 'Ambiente'" style="margin-left: 10px">
					<div class="radio-group">
						<label *ngFor="let option of vm.sections" (click)="setSection(option.label, $event)">
							<input
								type="radio"
								[name]="option.label"
								[value]="option.label"
								[ngModel]="vm.selectedSection"
							/>
							<span class="custom-radio"></span>
							{{ option.label }}
						</label>
					</div>
				</div>
				<div class="tab-content" *ngIf="selectedTab() === 'Mesa'">
					Mesa para:
					<div class="partysize">
						<button mat-icon-button (click)="decrease(vm.maxMinPartysize?.min)">
							<mat-icon svgIcon="pm-icons:minus" style="width: 32px; height: 32px"> </mat-icon>
						</button>
						<div class="partysize__people-container">
							<p class="partysize__number">{{ partysize() }}</p>
							<p class="partysize__people">
								{{ partysize() === 1 ? 'Pessoa' : 'Pessoas' }}
							</p>
						</div>
						<button mat-icon-button (click)="increase(vm.maxMinPartysize?.max)">
							<mat-icon svgIcon="pm-icons:plus" style="width: 32px; height: 32px"></mat-icon>
						</button>
					</div>
				</div>
				<div class="tab-content" *ngIf="selectedTab() === 'Horário'">
					<div class="radio-group grid">
						<label
							*ngFor="let option of vm.reservationTimes"
							(click)="setReservationTime(option.reservationTime, $event)"
						>
							<input
								type="radio"
								[name]="option.reservationTime"
								[value]="option.reservationTime"
								[ngModel]="vm.selectedReservationTime"
							/>
							<span class="custom-radio"></span>
							{{ option.reservationTime }}
						</label>
					</div>
					<div style="height: 150px"></div>
				</div>

				@if (selectedTab() === 'Horário' && vm.selectedReservationTime) {
					<feature-pm-terms-and-conditions-checkbox
						(changeInput)="toggleTermsAndConditions($event)"
						*ngIf="!eulaFromClient()"
					/>
					<ui-pm-footer-buttons type="fixed-order">
						<button
							mat-raised-button
							[color]="isReservationDisabled(vm) ? '' : 'primary'"
							[disabled]="isReservationDisabled(vm)"
							(click)="reserve()"
						>
							Reservar
						</button>
					</ui-pm-footer-buttons>
				} @else {
					<button
						mat-icon-button
						class="next-button"
						[disabled]="isDisabledTab(vm)"
						[ngClass]="{ disabled: isDisabledTab(vm), active: !isDisabledTab(vm) }"
						(click)="nextTab()"
					>
						<mat-icon
							svgIcon="pm-icons:right-arrow"
							style="width: 24px; height: 24px; transform: translateY(-2px)"
						></mat-icon>
					</button>
				}
			</section>
		</main>
		<section
			*ngIf="
				vm.statusConfirmReservation === 'success' &&
				vm.venue &&
				vm.client &&
				vm.confirmReservation.partySize &&
				vm.confirmReservation.reservationTime &&
				vm.confirmReservation.reservationDay &&
				vm.confirmReservation.sectionLabel
			"
			[ngClass]="{ principal: principal$ | async }"
		>
			<ui-pm-order-success
				type="reservation"
				[partySize]="partysize()"
				[venue]="vm.venue"
				[client]="vm.client"
				[partySize]="vm.confirmReservation.partySize"
				[reservationTime]="vm.confirmReservation.reservationTime"
				[sectionLabel]="vm.confirmReservation.sectionLabel"
				[orderDate]="vm.confirmReservation.reservationDay"
				[voucherValue]="vm.voucherValue"
				(ok)="okClick()"
			/>
		</section>
	}
}
