import { Component, HostBinding, inject, signal } from '@angular/core'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatExpansionModule } from '@angular/material/expansion'
import { CommonModule } from '@angular/common'
import { MatBottomSheetRef, MatBottomSheetModule } from '@angular/material/bottom-sheet'
import { MatButtonModule } from '@angular/material/button'
import { BottomSheetHeaderComponent, LoadingPageComponent } from '@monorepo-channels/components/ui-pm'
import { Store } from '@ngrx/store'
import {
	Address,
	AddressActions,
	getAddresses,
	getStatusAddress,
	VenueActions,
} from '@monorepo-channels/channels/domain'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { BodyClassObserverService, removeAccents } from '@monorepo-channels/shared/util-helpers'
import { startWith, distinctUntilChanged, switchMap, map } from 'rxjs'
import { FormControl, ReactiveFormsModule } from '@angular/forms'

@Component({
	standalone: true,
	imports: [
		CommonModule,
		MatFormFieldModule,
		MatInputModule,
		MatIconModule,
		MatExpansionModule,
		MatIconModule,
		MatBottomSheetModule,
		MatButtonModule,
		BottomSheetHeaderComponent,
		MatProgressSpinnerModule,
		LoadingPageComponent,
		ReactiveFormsModule,
	],
	selector: 'feature-pm-cities',
	templateUrl: './cities.component.html',
	styleUrls: ['./cities.component.scss'],
})
export class CitiesComponent {
	private store = inject(Store)

	statusAddress$ = this.store.select(getStatusAddress)
	addresses$ = this.store.select(getAddresses)

	private bottomSheetRef = inject<MatBottomSheetRef<CitiesComponent>>(MatBottomSheetRef)

	public searchCityControl = new FormControl('', { nonNullable: true })

	public principal$ = inject(BodyClassObserverService).observeClass('principal')

	filteredAddresses$ = this.searchCityControl.valueChanges.pipe(
		startWith(''), // Garante que todos os endereços sejam exibidos inicialmente
		distinctUntilChanged(),
		switchMap((searchCity: string) => {
			if (!searchCity.trim()) {
				return this.addresses$ // sem filtro se a pesquisa estiver vazia
			} else {
				return this.addresses$.pipe(
					map(addresses =>
						addresses.filter(
							address =>
								removeAccents(address.city)
									.toLowerCase()
									.includes(removeAccents(searchCity).toLowerCase()) ||
								address.state.toLowerCase().includes(searchCity.toLowerCase()) ||
								address.neighborhoods.some(neighborhood =>
									removeAccents(neighborhood.neighborhood)
										.toLowerCase()
										.includes(removeAccents(searchCity).toLowerCase())
								)
						)
					)
				)
			}
		})
	)

	constructor() {}
	step = signal(-1)

	@HostBinding('style')
	get style() {
		return {
			// '--mat-expansion-header-expanded-state-height': '38px',
			'--mat-expansion-header-collapsed-state-height': '38px',
		}
	}

	setStep(index: number) {
		this.step.set(index)
	}

	selectCity() {
		this.bottomSheetRef.dismiss()
	}

	setCurrentNeighborhoodAndAddress(neighborhood: string | undefined, address: Address | null) {
		this.store.dispatch(AddressActions.setCurrentNeighborhood({ neighborhood }))
		if (address) this.store.dispatch(AddressActions.setCurrentAddress({ address }))
		this.store.dispatch(VenueActions.applyFilter())
		this.bottomSheetRef.dismiss()
	}
}
