import { CommonModule } from '@angular/common'
import { Component, inject, signal } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { Category, ClientActions, getClient, VenueActions } from '@monorepo-channels/channels/domain'
import { BottomSheetHeaderComponent, FooterButtonsComponent } from '@monorepo-channels/components/ui-pm'
import { MatRippleModule } from '@angular/material/core'
import { Store } from '@ngrx/store'
import { MatButtonModule } from '@angular/material/button'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet'
import { Router } from '@angular/router'
import { BodyClassObserverService } from '@monorepo-channels/shared/util-helpers'

@Component({
	standalone: true,
	imports: [
		CommonModule,
		MatIconModule,
		BottomSheetHeaderComponent,
		FooterButtonsComponent,
		MatRippleModule,
		MatButtonModule,
	],
	templateUrl: './select-card-options.component.html',
	styleUrls: ['./select-card-options.component.scss'],
})
export class SelectCardOptionsComponent {
	private store = inject(Store)
	public client$ = this.store.select(getClient)
	private router = inject(Router)

	public selectedCategory = signal<Category | null | undefined>(undefined)

	private bottomSheetRef = inject<MatBottomSheetRef<SelectCardOptionsComponent>>(MatBottomSheetRef)
	public data: { venueId: string } | null = inject(MAT_BOTTOM_SHEET_DATA)

	public principal$ = inject(BodyClassObserverService).observeClass('principal')

	constructor() {
		this.client$.pipe(takeUntilDestroyed()).subscribe({
			next: client => {
				this.selectedCategory.set(client?.selectedCategory)
			},
		})
	}

	openRegisterCard() {
		this.router.navigate(['carteira/cadastrar-cartao'])
		this.bottomSheetRef.dismiss()
	}

	selectCategory() {
		const categoryId = this.selectedCategory()?.categoryId
		if (!categoryId) return
		this.store.dispatch(ClientActions.changeCategoryByCategoryId({ categoryId }))
		// we need to reload all venues because the benefits can change when selecting a different categoryId
		if (this.data?.venueId) {
			this.store.dispatch(VenueActions.loadOneVenue({ venueId: this.data.venueId }))
		}
		this.store.dispatch(VenueActions.applyFilter())
		this.bottomSheetRef.dismiss()
	}
}
