import { CommonModule } from '@angular/common'
import { Component, inject } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { FooterButtonsComponent } from '@monorepo-channels/components/ui-pm'
import { BodyClassObserverService } from '@monorepo-channels/shared/util-helpers'

@Component({
	selector: 'pm-privacy-policy-page',
	standalone: true,
	imports: [FooterButtonsComponent, MatButtonModule, CommonModule],
	templateUrl: 'privacy-policy.page.html',
	styleUrls: ['privacy-policy.page.scss'],
})
export class PrivacyPolicyPageComponent {
	public principal$ = inject(BodyClassObserverService).observeClass('principal')
}
