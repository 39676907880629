<article class="container" [ngClass]="{ principal: principal$ | async }">
	<header class="curved-background">
		<mat-icon
			svgIcon="pm-icons:check-circle"
			style="width: 66px; height: 66px; --icon-color-red: #fff"
		></mat-icon>
		<h1>{{ type === 'checkin' ? 'Check-in realizado!' : 'Reserva realizada' }}</h1>
	</header>

	<article class="px-20" style="margin-top: 20px">
		<section>
			<h2 class="title">Restaurante</h2>
			<p class="venue-title">{{ venue.name }}</p>
		</section>

		<section>
			<ul class="card-box-shadow" style="margin-top: 24px; margin-bottom: 24px">
				<li class="card-box-shadow__item">
					<mat-icon svgIcon="pm-icons:profile" style="width: 24px; height: 24px"></mat-icon>
					{{ client.fullName }}
				</li>
				<li class="card-box-shadow__item">
					<mat-icon svgIcon="pm-icons:profile-plus" style="width: 23px; height: 23px"> </mat-icon>
					{{ partySize }} {{ partySize === 1 ? 'pessoa' : 'pessoas' }}
				</li>
				<li class="card-box-shadow__item" *ngIf="sectionLabel">
					<mat-icon svgIcon="pm-icons:map" style="width: 23px; height: 23px"> </mat-icon>
					{{ sectionLabel }}
				</li>
				<li class="card-box-shadow__item">
					<mat-icon svgIcon="pm-icons:calendar" style="width: 23px; height: 23px"> </mat-icon>
					{{ orderDate | date: "EEEE, d 'de' MMMM" | capitalizeFirst }}
				</li>
				<li class="card-box-shadow__item">
					<mat-icon svgIcon="pm-icons:time" style="width: 23px; height: 23px"> </mat-icon>
					{{ reservationTime }}
				</li>
				<li class="card-box-shadow__item">
					<mat-icon svgIcon="pm-icons:map" style="width: 24px; height: 24px; flex-shrink: 0">
					</mat-icon>
					{{ venue.address.value }}
				</li>
			</ul>
		</section>

		<!-- <section style="display: flex; gap: 21px; margin-bottom: 12px" *ngIf="type === 'reservation'">
			<button mat-raised-button (click)="cancelReservation()">Cancelar reserva</button>
			<button mat-raised-button (click)="shareReservation()" style="position: relative">
				Compartilhar
				<div *ngIf="copied()" @fadeInOut class="copy-notification">Dados copiado</div>
			</button>
		</section> -->
	</article>
</article>

<section *ngIf="!voucherValue">
	<div style="background: var(--celebrations-background)">
		<h2
			class="benefits-title tablet-up-center"
			style="padding: 12px 20px; margin-bottom: 20px; --content-txt: #3c3c3c"
		>
			Benefícios do seu cartão
		</h2>
	</div>
	<article class="container px-20">
		<ui-pm-benefits type="expansion" [benefits]="venue.benefits"></ui-pm-benefits>
	</article>
</section>

<article class="container">
	<ui-pm-celebrations-ribbon *ngIf="voucherValue" [voucherValue]="voucherValue"></ui-pm-celebrations-ribbon>

	<article class="px-20">
		<footer>
			<ui-pm-footer-buttons [border]="false" (click)="ok.emit()">
				<button mat-raised-button color="primary">OK</button>
			</ui-pm-footer-buttons>
		</footer>
	</article>
</article>
