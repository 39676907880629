<ui-pm-bottom-sheet-header title="Selecionar cartão"></ui-pm-bottom-sheet-header>

@if (client$ | async; as client) {
	<div class="cards" [ngClass]="{ principal: principal$ }">
		<button
			mat-ripple
			*ngFor="let category of client.categories"
			class="card"
			[class.active]="category.categoryId === selectedCategory()?.categoryId"
			(click)="selectedCategory.set(category)"
		>
			<mat-icon svgIcon="pm-icons:wallet" style="width: 24px; height: 24px"></mat-icon>
			{{ category.nameCapitalized }}
			<mat-icon
				*ngIf="category.categoryId === selectedCategory()?.categoryId"
				svgIcon="pm-icons:check"
				style="width: 16px; height: 16px; margin-left: auto; margin-right: 15px"
			></mat-icon>
		</button>

		<button mat-raised-button (click)="openRegisterCard()">+ Adicionar cartão</button>
	</div>
}

<ui-pm-footer-buttons>
	<button mat-raised-button color="primary" (click)="selectCategory()">Confirmar</button>
</ui-pm-footer-buttons>
