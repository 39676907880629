import { Component, EventEmitter, inject, Input, Output } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatIconModule } from '@angular/material/icon'
import { Client, VenueFilter } from '@monorepo-channels/channels/domain'
import { BenefitsUiComponent } from '../benefits/benefits.component'
import { TagUiComponent } from '../tag/tag.component'
import { DivisorUiComponent } from '@monorepo-channels/components/ui-common'
import { BodyClassObserverService } from '@monorepo-channels/shared/util-helpers'

@Component({
	selector: 'ui-pm-venue-card',
	standalone: true,
	imports: [CommonModule, BenefitsUiComponent, TagUiComponent, DivisorUiComponent, MatIconModule],
	templateUrl: './venue-card.component.html',
	styleUrls: ['./venue-card.component.scss'],
})
export class VenueCardUiComponent {
	@Input({ required: true }) venue!: VenueFilter
	@Output() clickCard = new EventEmitter<void>()
	@Input() client: Client | null = null
	@Output() clickFavorite = new EventEmitter<string>()
	@Input() celebrations: boolean = false

	public principal$ = inject(BodyClassObserverService).observeClass('principal')

	toggleFavorite(event: MouseEvent) {
		event.stopPropagation()
		this.clickFavorite.emit(this.venue.id)
	}
}
