<ui-pm-bottom-sheet-header title="Filtros" />

<div style="height: 100%; overflow-y: auto">
	<p style="font-size: 18px; margin-bottom: 20px; padding: 0 16px">Cidades</p>

	<div style="padding: 0 16px; margin-bottom: 20px">
		<div class="search-input">
			<mat-icon
				class="search-icon"
				svgIcon="pm-icons:search"
				style="width: 14px; height: 14px; cursor: pointer"
				(click)="searchInput.focus()"
			></mat-icon>
			<input
				type="text"
				placeholder="Pesquise por estado, cidade ou bairro"
				[formControl]="searchCityControl"
				#searchInput
			/>
		</div>
	</div>

	@if (statusAddress$ | async; as statusAddress) {
		<ng-container *ngIf="statusAddress === 'pending'">
			<ui-pm-loading-page [ngClass]="{ principal: principal$ | async }" />
		</ng-container>
		<mat-accordion *ngIf="statusAddress === 'success'">
			<div
				style="display: flex; flex-direction: column; align-items: center; text-align: center"
				*ngIf="(filteredAddresses$ | async)?.length === 0"
			>
				<mat-icon
					svgIcon="pm-icons:map-pin"
					style="margin-bottom: 14px; width: 32px; height: 32px"
				></mat-icon>
				<p style="font-size: 16px; font-weight: 600">Local não encontrado.</p>
				<p>Refaça sua busca.</p>
			</div>
			<mat-expansion-panel
				[expanded]="step() === i"
				(opened)="setStep(i)"
				*ngFor="let address of filteredAddresses$ | async; let i = index; let last = last"
			>
				<mat-expansion-panel-header>
					<mat-panel-title>{{ address.city }} - {{ address.state }}</mat-panel-title>
				</mat-expansion-panel-header>

				<div style="padding: 0 12px">
					<button mat-button (click)="setCurrentNeighborhoodAndAddress(undefined, address)">
						Todos
					</button>
					<button
						mat-button
						*ngFor="let neighborhood of address.neighborhoods"
						(click)="setCurrentNeighborhoodAndAddress(neighborhood.neighborhood, address)"
					>
						{{ neighborhood.neighborhood }}
					</button>
				</div>
			</mat-expansion-panel>
		</mat-accordion>
	}
	<div style="height: 20vh"></div>
</div>
