<main class="container">
	<h1 class="title">Escolha como deseja seguir com a {{ order }}</h1>

	<article
		class="card-box-shadow"
		style="cursor: pointer"
		(click)="setCelebration('voucher')"
		[ngClass]="{ selected: selectCelebration() === 'voucher' }"
	>
		<div style="display: flex; align-items: center; gap: 11px">
			<mat-icon svgIcon="pm-icons:celebrations"></mat-icon>
			<div style="display: flex; flex-direction: column; gap: 4px; color: var(--content-txt)">
				<p style="font-size: 18px; font-weight: 480">Celebrations</p>
				<p style="font-size: 14px; font-weight: 360">
					Voucher de <span style="font-weight: 480">{{ voucherValue | currency: 'BRL' }}</span>
				</p>
			</div>
		</div>
	</article>

	<div style="height: 27px"></div>
	<ui-common-or-divisor style="margin: 27px 0"></ui-common-or-divisor>
	<div style="height: 27px"></div>
	<article
		class="card-box-shadow"
		style="cursor: pointer; gap: 16px; color: var(--content-txt)"
		(click)="setCelebration('benefits')"
		[ngClass]="{ selected: selectCelebration() === 'benefits' }"
	>
		<p style="font-size: 18px; font-weight: 480">Benefícios do seu cartão</p>
		<ui-pm-benefits [benefits]="benefits"></ui-pm-benefits>
	</article>
</main>
